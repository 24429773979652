import { call, put, takeLatest, takeEvery, take } from 'redux-saga/effects';
import { actions } from './slice';
import { request, requestAndCompleteResponse } from '../../../utils/request';
import Toast from '../../../services/toastService';
import userService from '../../../services/userService';
import { delayedRedirect, redirect } from '../../../utils/response';
import _ from 'lodash';
import queryString from 'query-string';
import FileDownload from 'downloadjs';
import { getFileNameFromHeader } from '../../../utils/functions';

export function* fetchRequests(action) {
  yield put(actions.fetchRequestsLoading());

  const filterQueryParams = { ...action.payload.filterQueryParams };

  Object.assign(filterQueryParams, { page: 1 });

  const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));

  try {
    const data = yield call(
      request,
      `legal-request/${action.payload.userId}?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestsSuccess({ requests: data.requests, supplierName: action.payload.supplierName }));

    // if (action.payload.successCallback) {
    //   yield call(action.payload.successCallback, data.requests)
    // }
  } catch (e) {
    yield put(actions.fetchRequestsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests ${e.message}`);
  }
}

export function* fetchExpandAllRequests(action) {
  yield put(actions.fetchExpandAllRequestsLoading());

  const filterQueryParams = { ...action.payload.filterQueryParams };

  Object.assign(filterQueryParams, { page: 1 });

  const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));

  try {
    const supplierRequests = {};
    for (const counterparty of action.payload.missingCounterparties) {
      const data = yield call(
        request,
        `legal-request/${action.payload.userId}?${queryStringed}&supplierName=${counterparty}`,
        {
          method: 'GET',
        },
      );
      supplierRequests[counterparty] = data.requests;
    }
    yield put(actions.fetchExpandAllRequestsSuccess(supplierRequests));

    // if (action.payload.successCallback) {
    //   yield call(action.payload.successCallback, data.requests)
    // }
  } catch (e) {
    yield put(actions.fetchExpandAllRequestsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests ${e.message}`);
  }
}

export function* fetchRequestsNewWorkflow(action) {
  if(!action.hideLoader){
    yield put(actions.fetchRequestsNewLoading());
  }

  const filterQueryParams = { ...action.payload.filterQueryParams };
  Object.assign(filterQueryParams, { page: 1 });
  const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));

  try {
    const data = yield call(
      request,
      `legal-request/new-workflow-list?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestsNewSuccess({ requests: data.requests, supplierName: action.payload.supplierName }));

    // if (action.payload.successCallback) {
    //   yield call(action.payload.successCallback, data.requests)
    // }
  } catch (e) {
    yield put(actions.fetchRequestsNewFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests ${e.message}`);
  }
}

export function* fetchExpandAllRequestsNewWorkflow(action) {
  yield put(actions.fetchExpandAllRequestsLoading());

  const filterQueryParams = { ...action.payload.filterQueryParams };

  Object.assign(filterQueryParams, { page: 1 });
  // const queryStringed = queryString.stringify(_.pickBy(filterQueryParams, _.identity));

  try {
    const supplierRequests = {};
    for (const counterparty of action.payload.missingCounterparties) {
        let tempObject = {...filterQueryParams, supplierName: counterparty};
        const queryStringed = queryString.stringify(_.pickBy(tempObject, _.identity));
        const data = yield call(
          request,
          `legal-request/new-workflow-list?${queryStringed}`,
          {
            method: 'GET',
          },
        );
        supplierRequests[counterparty] = data.requests;
    }
    yield put(actions.fetchExpandAllNewRequestsSuccess(supplierRequests));

    // if (action.payload.successCallback) {
    //   yield call(action.payload.successCallback, data.requests)
    // }
  } catch (e) {
    yield put(actions.fetchExpandAllRequestsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests ${e.message}`);
  }
}

export function* fetchRequestInfo(action) {
  yield put(actions.fetchRequestInfoLoading());

  try {
    const data = yield call(
      request,
      `legal-request/info/${action.payload.requestId}${(action.payload.newWorkflow) ? '?'+action.payload.newWorkflow : ''}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestInfoSuccess(data.legalRequest));
    if (action.payload.successCallback) {
      action.payload.successCallback(data.legalRequest)
    }
  } catch (e) {
    yield put(actions.fetchRequestInfoFailure(e.toString()));
    if (e.response && e.response.data && e.response.data.message) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests ${e.message}`);
  }
}


export function* getAttachments(action) {
  yield put(actions.getAttachmentsLoading());

  try {
    const data = yield call(
      request,
      `legal-request/attachments/${action.payload.requestId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.getAttachmentsSuccess(data.attachments));

  } catch (e) {
    yield put(actions.getAttachmentsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching attachments ${e.message}`);
  }
}

export function* fetchRequestHistoryById(action) {
  yield put(actions.fetchRequestHistoryByIdLoading());

  try {
    const data = yield call(
      request,
      `request-history/logs/${action.payload.requestId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestHistoryByIdSuccess(data.histories));

  } catch (e) {
    yield put(actions.fetchRequestHistoryByIdFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while fetching requests');
  }
}

export function* fetchRequestHistoryByArtefactId(action) {
  // yield put(actions.fetchRequestHistoryByArtefactIdLoading());

  try {
    const data = yield call(
      request,
      `request-history/artefact/logs/${action.payload.artefactId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestHistoryByArtefactIdSuccess(data.histories));

  } catch (e) {
    yield put(actions.fetchRequestHistoryByArtefactIdFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while fetching requests');
  }
}

export function* fetchAuditLogs(action) {
  yield put(actions.fetchAuditLogsLoading());

  try {
    const data = yield call(
      request,
      `request-history/audit-logs/${action.payload.legalRequestId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchAuditLogsSuccess(data.auditLogs));

  } catch (e) {
    yield put(actions.fetchAuditLogsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while fetching requests');
  }
}

export function* fetchCommentsByHistoryId(action) {
  // yield put(actions.fetchCommentsByHistoryIdLoading());

  try {
    const data = yield call(
      request,
      `request-history/comments/${action.payload.requestHistoryId}${action.payload.newWorkflow && action.payload.newWorkflow === true ? "?newWorkflow=true" : ''}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchCommentsByHistoryIdSuccess(data.comments.comments));

  } catch (e) {
    yield put(actions.fetchCommentsByHistoryIdFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching comments, ${e.message}`);
  }
}

export function* fetchSubUsers(action) {
  // yield put(actions.fetchSubUsersLoading());

  try {
    const data = yield call(
      request,
      `legal-request/fellow-sub-users/${action.payload.requestId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchSubUsersSuccess(data.users));

  } catch (e) {
    yield put(actions.fetchSubUsersFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching subusers, ${e.message}`);
  }
}

export function* fetchRequestUsers(action) {
  // yield put(actions.fetchRequestUsersLoading());

  try {
    const data = yield call(
      request,
      `legal-request/users/${action.payload.requestId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchRequestUsersSuccess(data.users));
    if (action.payload.successCallback) {
      action.payload.successCallback(data.users)
    }

  } catch (e) {
    yield put(actions.fetchRequestUsersFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching request users, ${e.message}`);
  }
}

export function* fetchCounterParty(action) {
  //yield put(actions.fetchCounterPartyLoading());
  let newWorkflowReq = false
  if(action.payload && action.payload.query && action.payload.query.newWorkflow){
    newWorkflowReq = true
  }
  const queryStringed = action.payload && action.payload.query !== undefined ? queryString.stringify(_.pickBy(action.payload.query, _.identity)) : '';
  try {
    const data = yield call(
      request,
      `counterparty/client?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchCounterPartySuccess({data:data.counterparties, newWorkflowReq:newWorkflowReq}));

    if (action.payload && action.payload.successCallback)
      yield call(action.payload.successCallback, data.counterparties)

  } catch (e) {
    yield put(actions.fetchCounterPartyFailure(e.toString()));
    if (e.response) {
      if (e.response.status !== 404) {
        Toast.error(e.response.data.message);
      }
      return;
    }
    Toast.error(`Some error occurred while fetching counterparty, ${e.message}`);
  }
}

// consolidated counterparties
export function* fetchCounterParties(action) {
  // yield put(actions.fetchCounterPartiesLoading());

  try {
    const data = yield call(
      request,
      `legal-request/get-consolidated-view-filters`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchCounterPartiesSuccess(data.counterParties));

  } catch (e) {
    yield put(actions.fetchCounterPartiesFailure(e.toString()));
    if (e.response) {
      if (e.response.status !== 404) {
        Toast.error(e.response.data.message);
      }
      return;
    }
    Toast.error(`Some error occurred while fetching counterparties, ${e.message}`);
  }
}

// consolidated subscribers
export function* fetchSubscribers(action) {
  // yield put(actions.fetchCounterPartiesLoading());

  try {
    const data = yield call(
      request,
      `legal-request/get-consolidated-view-filters`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchSubscribersSuccess(data.subscribers));

  } catch (e) {
    yield put(actions.fetchSubscribersFailure(e.toString()));
    if (e.response) {
      if (e.response.status !== 404) {
        Toast.error(e.response.data.message);
      }
      return;
    }
    Toast.error(`Some error occurred while filters, ${e.message}`);
  }
}

// consolidated lawyers
export function* fetchLawyersFilters(action) {
  // yield put(actions.fetchCounterLawyersLoading());

  try {
    const data = yield call(
      request,
      `legal-request/get-consolidated-view-filters`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchLawyersSuccess(data.lawyers));

  } catch (e) {
    yield put(actions.fetchLawyerFailure(e.toString()));
    if (e.response) {
      if (e.response.status !== 404) {
        Toast.error(e.response.data.message);
      }
      return;
    }
    Toast.error(`Some error occurred while fetching lawyers filter, ${e.message}`);
  }
}

export function* saveCounterParty(action) {
  yield put(actions.saveCounterPartyLoading());

  try {
    const data = yield call(
      request,
      `counterparty`,
      {
        method: 'POST',
        data: action.payload,
      },
    );
    yield put(actions.saveCounterPartySuccess(data.counterparty));
  } catch (e) {
    yield put(actions.saveCounterPartyFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending comment, ${e.message}`);
  }
}

export function* saveCommentByRequest(action) {
  yield put(actions.saveCommentByRequestLoading());

  try {
    const data = yield call(
      request,
      `request-history/comment/${action.payload.requestHistoryId}`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.saveCommentByRequestSuccess());
    // yield put(actions.fetchCommentsByHistoryIdSuccess(data.comments.comments));
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.saveCommentByRequestFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending comment, ${e.message}`);
  }
}

export function* saveRequest(action) {
  yield put(actions.saveRequestLoading());

  try {
    const data = yield call(
      request,
      `legal-request`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    const user = yield call(userService.getUser);
    yield put(actions.saveRequestSuccess(data.request));
    // Toast.success('Successfully submitted request');
    if (action.payload.successCallback) {
      action.payload.successCallback(data.request)
    }
    else {
      if (user && user.isAdmin) {
        redirect(`/?request=${data.request.id}`);
      }
      else redirect(`/workflow?request=${data.request.id}&counterparty=${data.request.supplierName}`);
    }

  } catch (e) {
    yield put(actions.saveRequestFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while submiting request');
  }
}

export function* saveRequestForWorkflow(action) {
  yield put(actions.saveRequestLoading());

  try {
    const data = yield call(
      request,
      `legal-request/new-workflow`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    const user = yield call(userService.getUser);
    yield put(actions.saveRequestSuccess(data.request));
    // Toast.success('Successfully submitted request');
    if (action.payload.successCallback) {
      action.payload.successCallback(data.request)
    }
    else {
      if (user && user.isAdmin) {
        redirect(`/?request=${data.request.id}`);
      }
      else redirect(`/workflow?request=${data.request.id}&counterparty=${data.request.supplierName}`);
    }

  } catch (e) {
    yield put(actions.saveRequestFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while submiting request');
  }
}

export function* updateRequestForWorkflow(action) {
  yield put(actions.updateRequestForWorkflowLoading());

  try {
    const data = yield call(
      request,
      `request-history/update-info/${action.payload.requestHistoryId}?info=${action.payload.info}`,
      {
        method: 'post',
        data: action.payload.data,
      },
    );
    // const user = yield call(userService.getUser);
    yield put(actions.updateRequestForWorkflowSuccess(data.results));
    // Toast.success('Successfully submitted request');
    if (action.payload.successCallback) {
      action.payload.successCallback(data.results)
    }
    // else {
    //   if (user && user.isAdmin) {
    //     redirect(`/?request=${data.request.id}`);
    //   }
    //   else redirect(`/workflow?request=${data.request.id}&counterparty=${data.request.supplierName}`);
    // }

  } catch (e) {
    yield put(actions.updateRequestForWorkflowFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error('Some error occurred while updating workflow');
  }
}

// save contract template and generate questionnaires
export function* saveContractTemplate(action) {
  yield put(actions.saveContractTemplateLoading());

  try {
    const data = yield call(
      request,
      `template/upload-template/${action.payload.templateId}/${action.payload.legalRequestId}/${action.payload.type}`
    );
    yield put(actions.saveContractTemplateSuccess(data.templates));
    Toast.success("Successfully saved template");
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.saveContractTemplateFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while gettings templates, ${e.message}`);
  }
}

// reinstate matters
export function* reinstateMatter(action) {
  yield put(actions.reinstateMatterLoading())

  try {
    const data = yield call(
      request,
      `legal-request/reinstate-matter/${action.payload.legalRequestId}`
    );
    yield put(actions.reinstateMatterSuccess(data));
    Toast.success("Successfully reinstated Matter")
    action.payload.successCallback()
  }
  catch (e) {
    yield put(actions.reinstateMatterFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while reinstating the matter, ${e.message}`);
  }

}

// reinstate artefacts
export function* reinstateArtefacts(action) {
  yield put(actions.reinstateArtefactsLoading())

  try {
    const data = yield call(
      request,
      `legal-request/reinstate-artefact/${action.payload.artefactId}`
    )
    yield put(actions.reinstateArtefactsSuccess(data));
    Toast.success("Successfully reinstated Artefact")
    action.payload.successCallback()

  }
  catch (e) {
    yield put(actions.reinstateArtefactsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while reinstating the artefact, ${e.message}`);
  }

}

export function* toggleQuestionnaires(action) {
  yield put(actions.toggleQuestionnairesSuccess(action.payload));
}

// save contract template and generate questionnaires
export function* saveQuestionnaires(action) {
  yield put(actions.saveQuestionnairesLoading());

  try {
    yield call(
      request,
      `legal-request/save-questionnaire/${action.payload.legalRequestId}`,
      {
        method: 'POST',
        data: { answers: action.payload.answersXML, }
      }
    );
    yield put(actions.toggleQuestionnaires(false));
    yield put(actions.saveQuestionnairesSuccess());
    Toast.success("Successfully saved answers");
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.saveQuestionnairesFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while saving questionnaires, ${e.message}`);
  }
}

export function* openCloseQuestionnaire(action) {
  yield put(actions.toggleQuestionnaires(action.payload));
}

// invite guest /invite-guest
export function* inviteGuest(action) {
  yield put(actions.inviteGuestLoading());

  try {
    yield call(
      request,
      `legal-request/invite-guest`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.inviteGuestSuccess());
    // Toast.success('Successfully sent invite');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.inviteGuestFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred, ${e.message}`);
  }
}

// invite sub user
export function* inviteSubUser(action) {
  yield put(actions.inviteSubUserLoading());

  try {
    yield call(
      request,
      `legal-request/invite-sub-user/${action.payload.legalRequest}${action.payload.dontSendMail && action.payload.dontSendMail === true ? "?sendEmail=false" : ''}`,
      {
        method: 'POST',
        data: {
          userId: action.payload.userId,
        },
      }
    );
    yield put(actions.inviteSubUserSuccess());
    // Toast.success('Successfully sent invite');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.inviteSubUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      if (action.payload.errorCallback) {
        action.payload.errorCallback()
      }
      else return;
    }
    //Toast.error(`Some error occurred, ${e.message}`);
  }
}



export function* inviteCollaborator(action) {
  yield put(actions.inviteSubUserLoading());

  try {
    yield call(
      request,
      `legal-request/invite-collaborator-user/${action.payload.legalRequest}`,
      {
        method: 'POST',
        data: {
          userId: action.payload.userId,
        },
      }
    );
    yield put(actions.inviteSubUserSuccess());
    // Toast.success('Successfully sent invite');
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.inviteSubUserFailure(e.toString()));
    if (e.response) {
      // Toast.error(e.response.data.message);
      if (action.payload.errorCallback) {
        action.payload.errorCallback()
      }
      else return;
    }
    //Toast.error(`Some error occurred, ${e.message}`);
  }
}

export function* getCollaborators(action) {
  yield put(actions.inviteSubUserLoading());

  try {
    const data = yield call(
      request,
      `legal-request/get-collaborator-user/${action.payload.legalRequest}`,
      {
        method: 'Get',
        // data: {
        //   userId: action.payload.userId,
        // },
      }
    );
    yield put(actions.inviteSubUserSuccess());
    // Toast.success('Successfully sent invite');
    action.payload.successCallback(data.collaborators);
  } catch (e) {
    yield put(actions.inviteSubUserFailure(e.toString()));
    if (e.response) {
      // Toast.error(e.response.data.message);
      if (action.payload.errorCallback) {
        action.payload.errorCallback()
      }
      else return;
    }
    //Toast.error(`Some error occurred, ${e.message}`);
  }
}

export function* notify(action) {
  yield put(actions.notifyLoading());

  try {
    yield call(
      request,
      `legal-request/notify/${action.payload.type}/${action.payload.legalRequestId}`,
      {
        method: "POST",
        data: action.payload.data,
      }
    );
    yield put(actions.notifySuccess());
    Toast.success(`Users notified by ${action.payload.type}`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.notifyFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending notification: ${e.message}`);
  }
}

export function* markComplete(action) {
  yield put(actions.markCompleteLoading());

  try {
    yield call(
      request,
      `legal-request/mark-complete/${action.payload.legalRequestId}`,
    );
    yield put(actions.markCompleteSuccess(action.payload.legalRequestId));
    Toast.success(`Successfully marked complete`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.markCompleteFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while marking complete: ${e.message}`);
  }
}

export function* markArtefactComplete(action) {
  yield put(actions.markArtefactCompleteLoading());

  try {
    yield call(
      request,
      `legal-request/mark-artefact-complete/${action.payload.artefactId}`,
    );
    yield put(actions.markArtefactCompleteSuccess({ artefactId: action.payload.artefactId, legalRequestId: action.payload.legalRequestId }));
    Toast.success(`Successfully marked complete`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.markArtefactCompleteFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while marking complete: ${e.message}`);
  }
}


export function* assignLegalRequest(action) {
  yield put(actions.assignLegalRequestLoading());

  try {
    yield call(
      request,
      `legal-request/assign-contract/${action.payload.legalRequestId}/${action.payload.newOwnerId}${action.payload.newWorkflow && action.payload.newWorkflow === true ? "?newWorkflow=true" : ''}`,
    );
    yield put(actions.assignLegalRequestSuccess(action.payload.legalRequestId));
    // Toast.success(`Successfully assigned legal request`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.assignLegalRequestFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while assigning request: ${e.message}`);
  }
}

export function* downloadLegalRequestDocument(action) {
  yield put(actions.downloadLegalRequestDocumentLoading());

  try {
    const response = yield call(
      requestAndCompleteResponse,
      `legal-request/download-contract-document/${action.payload.legalRequestId}`,
      {
        responseType: 'blob',
      },
    );
    yield put(actions.downloadLegalRequestDocumentSuccess());
    yield call(FileDownload, response.data, getFileNameFromHeader(response));
    Toast.success(`Successfully downloaded document`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.downloadLegalRequestDocumentFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while downloading: ${e.message}`);
  }
}

export function* uploadLegalRequestDocument(action) {
  yield put(actions.uploadLegalRequestDocumentLoading());

  try {
    yield call(
      request,
      `legal-request/upload-contract-document/${action.payload.legalRequestId}`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.uploadLegalRequestDocumentSuccess());
    action.payload.successCallback();
    Toast.success(`Successfully uploaded document`);
  } catch (e) {
    yield put(actions.uploadLegalRequestDocumentFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* uploadAttachments(action) {
  yield put(actions.uploadAttachmentsLoading());

  try {
    yield call(
      request,
      `legal-request/upload-attachment/${action.payload.legalRequestId}${(action.payload.newWorkflow && action.payload.newWorkflow === true) ? '?newWorkflow=true' : ''}`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.uploadAttachmentsSuccess());
    action.payload.successCallback();
    Toast.success(`Successfully uploaded document`);
  } catch (e) {
    yield put(actions.uploadAttachmentsFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* deleteAttachments(action) {
  yield put(actions.deleteAttachmentsLoading());

  try {
    yield call(
      request,
      `legal-request/delete-attachment/${action.payload.legalRequestId}/${action.payload.attachmentId}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteAttachmentsSuccess(action.payload.attachmentId));
    Toast.success(`Successfully deleted attachment`);
  } catch (e) {
    yield put(actions.deleteAttachmentsFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while deleting: ${e.message}`);
  }
}

export function* deleteLegalRequestDocument(action) {
  yield put(actions.deleteLegalRequestDocumentLoading());

  try {
    yield call(
      request,
      `legal-request/${action.payload.legalRequestId}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteLegalRequestDocumentSuccess(action.payload.legalRequestId));
    
    if(action.payload.successCallback){
      action.payload.successCallback()
    }
    Toast.success(`Successfully deleted matter`);
  } catch (e) {
    yield put(actions.deleteLegalRequestDocumentFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while deleting: ${e.message}`);
  }
}

export function* fetchAssignNewOwner(action) {
  yield put(actions.fetchAssignNewOwnerLoading());
  try {
    const data = yield call(
      request,
      `legal-request/assign-artefact/${action.payload.legalRequestId}/${action.payload.templateId}/${action.payload.artefactId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchAssignNewOwnerSuccess(data));
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.fetchAssignNewOwnerFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while assignining new owner, ${e.message}`);
  }
}

export function* addNewArtefact(action) {
  yield put(actions.addNewArtefactLoading());

  try {
    const data = yield call(
      request,
      `legal-request/artefact`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.addNewArtefactSuccess(data.legalRequestArtefact));
    action.payload.successCallback(data.legalRequestArtefact);
    Toast.success(`Successfully added artefact`);
  } catch (e) {
    yield put(actions.addNewArtefactFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while adding Artefact: ${e.message}`);
  }
}

export function* addNewArtefactInNewWorkflow(action) {
  yield put(actions.addNewArtefactLoading());

  try {
    const data = yield call(
      request,
      `legal-request/new-workflow-artefact`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.addNewArtefactSuccess(data.legalRequestArtefact));
    action.payload.successCallback(data.legalRequestArtefact);
    Toast.success(`Successfully added artefact`);
  } catch (e) {
    yield put(actions.addNewArtefactFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while adding Artefact: ${e.message}`);
  }
}

export function* fetchUploadArtefactById(action) {
  yield put(actions.fetchUploadArtefactByIdLoading());
  try {
    const data = yield call(
      request,
      `template/upload-artefact-template/${action.payload.templateId}/${action.payload.artefactId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchUploadArtefactByIdSuccess(data.templates));
    //yield put(actions.saveContractTemplateSuccess(data.templates));
    Toast.success("Successfully saved template");
    if (action.payload.successCallback) {
      action.payload.successCallback();
    }
  } catch (e) {
    yield put(actions.fetchUploadArtefactByIdFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
  }
}

export function* fetchUploadArtefactStepById(action) {
  yield put(actions.fetchUploadArtefactByIdLoading());
  try {
    const data = yield call(
      request,
      `template/upload-artefact-step-template/${action.payload.templateId}/${action.payload.artefactId}/${action.payload.requestHistoryId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchUploadArtefactByIdSuccess(data.templates));
    //yield put(actions.saveContractTemplateSuccess(data.templates));
    Toast.success("Successfully saved template");
    if (action.payload.successCallback) {
      action.payload.successCallback();
    }
  } catch (e) {
    yield put(actions.fetchUploadArtefactByIdFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
  }
}

export function* fetchSaveArtefact(action) {
  yield put(actions.fetchSaveArtefactLoading());
  try {
    yield call(
      request,
      `legal-request/save-artefact-questionnaire/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: { answers: action.payload.answersXML, }
      }
    );
    yield put(actions.toggleQuestionnaires(false));
    yield put(actions.fetchSaveArtefactSuccess());
    Toast.success("Successfully saved answers");
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.fetchSaveArtefactFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while saving questionnaires, ${e.message}`);
  }
}

export function* fetchSaveArtefactStep(action) {
  yield put(actions.fetchSaveArtefactLoading());
  try {
    yield call(
      request,
      `legal-request/save-artefact-step-questionnaire/${action.payload.artefactId}/${action.payload.requestHistoryId}`,
      {
        method: 'POST',
        data: { answers: action.payload.answersXML, }
      }
    );
    yield put(actions.toggleQuestionnaires(false));
    yield put(actions.fetchSaveArtefactSuccess());
    Toast.success("Successfully saved answers");
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.fetchSaveArtefactFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while saving questionnaires, ${e.message}`);
  }
}

export function* fetchUploadArtifactDoc(action) {
  try {
    yield call(
      request,
      `legal-request/upload-artefact-document/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.fetchUploadArtifactDocSuccess());
    Toast.success(`Successfully uploaded document`);
    if (action.payload.successCallback) {
      action.payload.successCallback();
    }

  } catch (e) {
    yield put(actions.fetchUploadArtifactDocFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* fetchUploadArtefactHistoryDoc(action) {
  try {
    yield call(
      request,
      `request-history/upload-document/${action.payload.artefactId}/${action.payload.requestHistoryId}`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.fetchUploadArtifactDocSuccess());
    Toast.success(`Successfully uploaded document`);
    if (action.payload.successCallback) {
      action.payload.successCallback();
    }

  } catch (e) {
    yield put(actions.fetchUploadArtifactDocFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* fetchDownloadArtifactDoc(action) {
  yield put(actions.fetchDownloadArtifactDocLoading());

  try {
    const response = yield call(
      requestAndCompleteResponse,
      `legal-request/download-artefact-document/${action.payload.artefactId}`,
      {
        responseType: 'blob',
      },
    );
    yield put(actions.fetchDownloadArtifactDocSuccess());
    yield call(FileDownload, response.data, getFileNameFromHeader(response));
    Toast.success(`Successfully downloaded document`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.fetchDownloadArtifactDocFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while downloading: ${e.message}`);
  }
}

export function* fetchDownloadArtifactDocById(action) {
  // yield put(actions.fetchDownloadArtifactDocLoading());
  try {
    const response = yield call(
      requestAndCompleteResponse,
      `legal-request/download-artefact-document-by-id/${action.payload.artefactId}/${action.payload.contractDocument}${(action.payload.requestHistoryId) ? '?requestHistoryId='+action.payload.requestHistoryId : ''}`,
      {
        responseType: 'blob',
      },
    );
    // yield put(actions.fetchDownloadArtifactDocSuccess());
    yield call(FileDownload, response.data, getFileNameFromHeader(response));
    Toast.success(`Successfully downloaded document`);
    action.payload.successCallback();
  } catch (e) {
    // yield put(actions.fetchDownloadArtifactDocFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while downloading: ${e.message}`);
  }
}

export function* deleteLegalRequestUser(action) {
  try {
    yield call(
      request,
      `legal-request/users/delete${action.payload.newWorkflow && action.payload.newWorkflow === true ? "?newWorkflow=true" : ''}`,
      {
        method: 'POST',
        data: action.payload.data
      }
    );
    yield put(actions.deleteLegalRequestUserSuccess());
    action.payload.successCallback();
    Toast.success(`User successfully deleted`);
  } catch (e) {
    yield put(actions.deleteLegalRequestUserFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while uploading: ${e.message}`);
  }
}

export function* deleteArtefact(action) {
  yield put(actions.deleteArtefactLoading());

  try {
    yield call(
      request,
      `legal-request/artefact/${action.payload.artefactId}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteArtefactSuccess());
    Toast.success(`Successfully deleted artefact`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.deleteArtefactFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while deleting: ${e.message}`);
  }
}
export function* updateArtefactSupportingData(action) {
  // yield put(actions.updateArtefactSupportingDataLoading());
  try {
    yield call(
      request,
      `legal-request/artefact/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.updateArtefactSupportingDataSuccess());
    Toast.success(`Successfully updated Supporting Data`);
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.updateArtefactSupportingDataFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating data: ${e.message}`);
  }
}

// update counterparty name 
export function* updateCounterPartyName(action) {
  // yield put(actions.updateCounterPartyLoading());
  try {
    yield call(
      request,
      `counterparty/${action.payload.nameId}`,
      {
        method: 'PATCH',
        data: action.payload.data,
      }
    );
    yield put(actions.updateCounterPartySuccess());
    Toast.success(`Successfully updated CounterParty name`)
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.updateCounterPartyFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating CounterParty name: ${e.message}`);

  }
}

// delete counterparty name 

export function* deleteCounterPartyName(action) {
  yield put(actions.deleteCounterPartyLoading());

  try {
    yield call(
      request,
      `counterparty/${action.payload.nameId}`,
      {
        method: 'DELETE',
      }
    );
    yield put(actions.deleteCounterPartySuccess(action.payload.nameId));

    Toast.success('Successfully deleted Counterparty');
    action.payload.successCallback()
  } catch (e) {
    yield put(actions.deleteCounterPartyFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while deleting CounterParty name`);
  }
}

export function* bulkArtefactSupportingDataUpdate(action) {
  yield put(actions.bulkArtefactSupportingDataUpdateLoading());
  try {
    yield call(
      request,
      `legal-request/artefact/bulk-update`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.bulkArtefactSupportingDataUpdateSuccess());
    Toast.success(`Successfully updated Supporting Data`);
    action.payload.successCallback()
  } catch (e) {
    yield put(actions.bulkArtefactSupportingDataUpdateFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating data: ${e.message}`);
  }
}

export function* uploadMediaFile(action) {
  yield put(actions.uploadMediaFileLoading());
  try {
    const data = yield call(
      request,
      `media/multi-upload`,
      {
        method: 'post',
        data: action.payload.data,
        headers: {
          'Content-Type': `multipart/form-data; boundary=${Math.random().toString().substr(2)}`,
        }
      },
    );
    yield put(actions.uploadMediaFileSuccess(data.medias));
    yield call(action.payload.successCallback, data.medias);
    Toast.success(`Successfully uploaded file`);
  } catch (e) {
    console.log(e);
    yield put(actions.uploadMediaFileFailure(e.toString()));
    Toast.error('Some error occurred while uploading file');
  }
}


export function* deleteMediaFile(action) {
  yield put(actions.deleteMediaFileLoading());
  try {
    const data = yield call(
      request,
      `media/delete-multi`,
      {
        method: 'post',
        data: action.payload.data
      },
    );
    yield put(actions.deleteMediaFileSuccess(action.payload.data));
    yield call(action.payload.successCallback, data.deletedMedias);
    Toast.success(`Successfully deleted file`);
  } catch (e) {
    yield put(actions.deleteMediaFileFailure(e.toString()));
    Toast.error('Some error occurred while deleting file');
  }
}

export function* updateLegalRequestName(action) {
  yield put(actions.updateLegalRequestNameLoading());
  try {
    const data = yield call(
      request,
      `legal-request/update-name?matterId=${action.payload.legalRequestId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.updateLegalRequestNameSuccess());
    Toast.success(`Successfully updated Matter name`);
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.updateLegalRequestNameFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating data: ${e.message}`);
  }
}

export function* fetchDocumentHistory(action) {
  // yield put(actions.fetchAssignNewOwnerLoading());
  try {
    const data = yield call(
      request,
      `legal-request/artefact/document-history?artefactId=${action.payload.artefactId}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.fetchDocumentHistorySuccess(data));
    action.payload.successCallback();
  } catch (e) {
    // yield put(actions.fetchAssignNewOwnerFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching document history, ${e.message}`);
  }
}

//deleted artefacts
export function* deletedArtefact(action) {
  // yield put (actions.deletedArtefactLoading())
  const queryStringed = queryString.stringify(_.pickBy(action?.payload?.filters, _.identity));
  try {
    const data = yield call(
      request,
      `legal-request/deleted-artefact/${action.payload.userId}?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.deletedArtefactSuccess(data.artefacts));
  }
  catch (e) {
    yield put(actions.deletedArtefactFailure(e.toString()));
    Toast.error(`Some error occurred while fetching deleted artefacts, ${e.message}`);
  }
}

// ddeleted matters 
export function* deletedMatter(action) {
  // yield put (actions.deletedMatterLoading())
  const queryStringed = queryString.stringify(_.pickBy(action.payload.filters, _.identity));
  try {
    const data = yield call(
      request,
      `legal-request/deleted-matter/${action.payload.userId}?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    yield put(actions.deletedMatterSuccess(data.requests));
  }
  catch (e) {
    yield put(actions.deletedMatterFailure(e.toString()));
    Toast.error(`Some error occurred while fetching deleted matters, ${e.message}`);
  }
}

export function* updateArtefactStatus(action) {
  // yield put(actions.updateLegalRequestNameLoading());
  try {
    const data = yield call(
      request,
      `legal-request/change-artefact-status/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: action.payload.data.data,
      }
    );
    // yield put(actions.updateLegalRequestNameSuccess());
    Toast.success(`Successfully updated Artefact status`);
    if(action.payload.successCallback){
      action.payload.successCallback(data);
    }
  } catch (e) {
    // yield put(actions.updateLegalRequestNameFaliure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating artefact status: ${e.message}`);
  }
}

// update signed contract 
export function* saveSignedContractModal(action) {
  yield put(actions.saveModalSignedContractLoading());
  try {
    yield call(
      request,
      `legal-request/update-renewal-fields/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.saveModalSignedContractSuccess());
    Toast.success(`Successfully updated renewal fields`);
    if (action.payload.successCallback) yield call(action.payload.successCallback);

  } catch (e) {
    yield put(actions.saveModalSignedContractFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating : ${e.message}`);
  }
}

// update signed contract 
export function* updateArtefactRenewed(action) {
  yield put(actions.updateArtefactRenewedLoading());
  try {
    yield call(
      request,
      `legal-request/update-renewed/${action.payload.artefactId}`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.updateArtefactRenewedSuccess());
    Toast.success(`Successfully updated renewal fields`);
    if (action.payload.successCallback) yield call(action.payload.successCallback);

  } catch (e) {
    yield put(actions.updateArtefactRenewedFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while updating : ${e.message}`);
  }
}

export function* fetchRequestAcrossPlatforms(action) {
  yield put(actions.fetchRequestAcrossPlatformsLoading());
  // const { page, limit } = action.payload.filters

  const queryStringed = queryString.stringify(_.pickBy(action.payload.filters, _.identity));
  try {
    const data = yield call(
      request,
      `legal-request/get-requests-across-all-platforms?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    const finalResult = {
      data: data ? data : [],
    }
    yield put(actions.fetchRequestAcrossPlatformsSuccess(finalResult));
    if (action.payload.successCallback) {
      action.payload.successCallback(finalResult.data);
    }
  } catch (e) {
    yield put(actions.fetchRequestAcrossPlatformsFailure(e.toString()));
    if (e.response) {
      // Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests, ${e.message}`);
  }
}

// fetch csv data for consolidated view
export function* fetchCsvData(action) {
  yield put(actions.fetchCsvDataLoading());
  // const { page, limit } = action.payload.filters

  const queryStringed = queryString.stringify(_.pickBy(action.payload.filters, _.identity));
  try {
    const data = yield call(
      request,
      `legal-request/get-requests-across-all-platforms-csv-download?${queryStringed}`,
      {
        method: 'GET',
      },
    );
    const finalResult = {
      data: data ? data : [],
    }
    yield put(actions.fetchCsvDataSuccess(finalResult));
    if (action.payload.successCallback) {
      action.payload.successCallback(finalResult.data);
    }
  } catch (e) {
    yield put(actions.fetchCsvDataFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests, ${e.message}`);
  }
}

// fetch counter party detail

export function* fetchCounterPartyDetail(action) {
  yield put(actions.fetchCounterPartyDetailLoading());
  const queryStringed = queryString.stringify(_.pickBy(action?.payload?.filters, _.identity));

  try {
    const data = yield call(
      request,
      `counterparty/matter-count?${queryStringed}`,
      {
        method: "GET"
      },
    );
    yield put(actions.fetchCounterPartyDetailSuccess(data));

  } catch (e) {
    yield put(actions.fetchCounterPartyDetailFailure(e.toString()));
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while fetching requests, ${e.message}`);

  }
}

// save lawyers
export function* saveLawyers(action) {
  yield put(actions.saveLawyerLoading());
  try {
    const data = yield call(
      request,
      `legal-request/update-lawyer-timeline-across-platforms`,
      {
        method: 'POST',
        data: action.payload.data,
      }
    );
    yield put(actions.saveLawyerSuccess());
    Toast.success(`Successfully saved Lawyer`);
    if (action.payload.successCallback) {
      action.payload.successCallback()
    }

  } catch (e) {
    yield put(actions.saveLawyerFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while saving Lawyers: ${e.message}`);
  }
}

// update consolidate fields  
export function* updateConsolidatedFields(action) {
  yield put(actions.updateConsolidatedFieldsLoading());
  try {
    yield call(
      request,
      `users/update-consolidated-fields-selection`,
      {
        method: 'post',
        data: action.payload.data
      }
    )
    yield put(actions.updateConsolidatedFieldsSuccess());
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.updateConsolidatedFieldsFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }

}


export function* fetchMasterContractData(action) {
  // yield put(actions.fetchMasterContractDataLoading());
  try {
    const data = yield call(
      request,
      `legal-request/master-contract-list`,
      {
        method: 'post',
        data: { supplierName: action.payload.supplierName }
      }
    )
    yield put(actions.fetchMasterContractDataSuccess(data.masterContractsList));
    action.payload.successCallback();
  } catch (e) {
    yield put(actions.fetchMasterContractDataFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }
}

export function* onUpdatePrimaryArtefact(action) {
  yield put(actions.updatePrimaryArtefactLoading());
  try {
    const data = yield call(
      request,
      `legal-request/change-primary-artefact`,
      {
        method: 'post',
        data: {
          "legalRequestArtefactId":action.payload.legalRequestArtefactId,
          "newArtefactId": action.payload.newArtefactId
        }
      }
    )
    yield put(actions.updatePrimaryArtefactSuccess());
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.updatePrimaryArtefactFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }
}

export function* getStepNames(action) {
  yield put(actions.getStepNamesLoading());
  try {
    const data = yield call(
      request,
      `workflow/subscriber/steps`,
      {
        method: 'get',
      }
    )
    yield put(actions.getStepNamesSuccess(data));
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.getStepNamesFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }
}

export function* createTimeLog(action) {
  // yield put(actions.createTimeLogLoading());

  try {
    const data = yield call(
      request,
      `timelog`,
      {
        method: 'POST',
        data: action.payload.data,
      },
    );
    yield put(actions.createTimeLogLoadingDone());
    Toast.success(`Successfully created time log`);
    action.payload.successCallback()
   
  } catch (e) {
    yield put(actions.createTimeLogLoadingDone());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending comment, ${e.message}`);
  }
}

export function* editTimeLog(action) {
  // yield put(actions.createTimeLogLoading());

  try {
    const data = yield call(
      request,
      `timelog/${action.payload.timelogId}`,
      {
        method: 'PUT',
        data: action.payload.data,
      },
    );
    yield put(actions.createTimeLogLoadingDone());
    Toast.success(`Successfully updated time log`);
    action.payload.successCallback()
   
  } catch (e) {
    console.log("in catcch =", e)
    yield put(actions.createTimeLogLoadingDone());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending comment, ${e.message}`);
  }
}

export function* deleteTimeLog(action) {
  // yield put(actions.createTimeLogLoading());

  try {
    const data = yield call(
      request,
      `timelog/${action.payload.timelogId}`,
      {
        method: 'DELETE'
      },
    );
    yield put(actions.createTimeLogLoadingDone());
    Toast.success(`Successfully deleted time log`);
    action.payload.successCallback()
   
  } catch (e) {
    console.log("in catcch =", e)
    yield put(actions.createTimeLogLoadingDone());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }
    Toast.error(`Some error occurred while sending comment, ${e.message}`);
  }
}

export function* getTimeLogForArtefact(action) {
  // yield put(actions.createTimeLogLoading());
  try {
    const data = yield call(
      request,
      `timelog/${action.payload.artefactId}`,
      {
        method: 'get',
      }
    )
    yield put(actions.createTimeLogLoadingDone());
    action.payload.successCallback(data.result);
  } catch (e) {
    yield put(actions.createTimeLogLoadingDone());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }
}

export function* getWorkflowSteps(action) {
  yield put(actions.getStepNamesLoading());
  try {
    const data = yield call(
      request,
      `/workflow/${action.payload.workflowId}`,
      {
        method: 'get',
      }
    )
    yield put(actions.getStepNamesSuccess(data));
    action.payload.successCallback(data);
  } catch (e) {
    yield put(actions.getStepNamesFailure());
    if (e.response) {
      Toast.error(e.response.data.message);
      return;
    }

  }
}

export function* legalRequestSaga() {
  yield takeEvery(actions.updatePrimaryArtefact.type, onUpdatePrimaryArtefact);
  yield takeEvery(actions.deleteAttachments.type, deleteAttachments);
  yield takeLatest(actions.uploadAttachments.type, uploadAttachments);
  yield takeLatest(actions.getAttachments.type, getAttachments);
  yield takeLatest(actions.fetchRequestInfo.type, fetchRequestInfo);
  yield takeLatest(actions.fetchRequests.type, fetchRequests);
  yield takeLatest(actions.fetchExpandAllRequests.type, fetchExpandAllRequests);
  yield takeLatest(actions.fetchRequestsNewWorkflow.type, fetchRequestsNewWorkflow);
  yield takeLatest(actions.fetchExpandAllRequestsNewWorkflow.type, fetchExpandAllRequestsNewWorkflow);
  yield takeLatest(actions.fetchRequestHistoryById.type, fetchRequestHistoryById);
  yield takeLatest(actions.fetchRequestHistoryByArtefactId.type, fetchRequestHistoryByArtefactId);
  yield takeLatest(actions.saveRequest.type, saveRequest);
  yield takeLatest(actions.saveRequestForWorkflow.type, saveRequestForWorkflow);
  yield takeLatest(actions.saveContractTemplate.type, saveContractTemplate);
  yield takeLatest(actions.saveQuestionnaires.type, saveQuestionnaires);
  yield takeLatest(actions.toggleQuestionnaires.type, toggleQuestionnaires);
  yield takeLatest(actions.inviteGuest.type, inviteGuest);
  yield takeLatest(actions.inviteSubUser.type, inviteSubUser);
  yield takeLatest(actions.notify.type, notify);
  yield takeLatest(actions.markComplete.type, markComplete);
  yield takeLatest(actions.markArtefactComplete.type, markArtefactComplete);
  yield takeLatest(actions.assignLegalRequest.type, assignLegalRequest);
  yield takeLatest(actions.fetchCommentsByHistoryId.type, fetchCommentsByHistoryId);
  yield takeLatest(actions.saveCommentByRequest.type, saveCommentByRequest);
  yield takeLatest(actions.uploadLegalRequestDocument.type, uploadLegalRequestDocument);
  yield takeLatest(actions.downloadLegalRequestDocument.type, downloadLegalRequestDocument);
  yield takeLatest(actions.deleteLegalRequestDocument.type, deleteLegalRequestDocument);
  yield takeLatest(actions.openCloseQuestionnaire.type, openCloseQuestionnaire);
  yield takeLatest(actions.fetchSubUsers.type, fetchSubUsers);
  yield takeLatest(actions.fetchRequestUsers.type, fetchRequestUsers)
  yield takeLatest(actions.saveCounterParty.type, saveCounterParty)
  yield takeLatest(actions.fetchCounterParty.type, fetchCounterParty)
  yield takeLatest(actions.fetchUploadArtefactById.type, fetchUploadArtefactById);
  yield takeLatest(actions.fetchAssignNewOwner.type, fetchAssignNewOwner)
  yield takeLatest(actions.addNewArtefact.type, addNewArtefact)
  yield takeLatest(actions.addNewArtefactInNewWorkflow.type, addNewArtefactInNewWorkflow)
  yield takeLatest(actions.fetchSaveArtefact.type, fetchSaveArtefact)
  yield takeLatest(actions.fetchSaveArtefactStep.type, fetchSaveArtefactStep)
  yield takeLatest(actions.fetchUploadArtifactDoc.type, fetchUploadArtifactDoc)
  yield takeLatest(actions.fetchDownloadArtifactDoc.type, fetchDownloadArtifactDoc)
  yield takeLatest(actions.deleteLegalRequestUser.type, deleteLegalRequestUser)
  yield takeLatest(actions.deleteArtefact.type, deleteArtefact)
  yield takeEvery(actions.updateArtefactSupportingData.type, updateArtefactSupportingData)
  yield takeEvery(actions.bulkArtefactSupportingDataUpdate.type, bulkArtefactSupportingDataUpdate)
  yield takeEvery(actions.uploadMediaFile.type, uploadMediaFile);
  yield takeEvery(actions.deleteMediaFile.type, deleteMediaFile);
  yield takeEvery(actions.updateLegalRequestName.type, updateLegalRequestName);
  yield takeLatest(actions.fetchDocumentHistory.type, fetchDocumentHistory)
  yield takeLatest(actions.fetchDownloadArtifactDocById.type, fetchDownloadArtifactDocById)
  yield takeLatest(actions.updateArtefactStatus.type, updateArtefactStatus)
  yield takeLatest(actions.fetchRequestAcrossPlatforms.type, fetchRequestAcrossPlatforms)
  yield takeLatest(actions.saveLawyers.type, saveLawyers)
  yield takeLatest(actions.fetchCounterParties.type, fetchCounterParties)
  yield takeLatest(actions.fetchSubscribers.type, fetchSubscribers)
  yield takeLatest(actions.fetchLawyers.type, fetchLawyersFilters)
  yield takeLatest(actions.updateCounterParty.type, updateCounterPartyName)
  yield takeLatest(actions.deleteCounterParty.type, deleteCounterPartyName)
  yield takeLatest(actions.updateConsolidatedFields.type, updateConsolidatedFields)
  yield takeLatest(actions.deletedArtefact.type, deletedArtefact)
  yield takeLatest(actions.deletedMatter.type, deletedMatter)
  yield takeLatest(actions.reinstateMatter.type, reinstateMatter)
  yield takeLatest(actions.reinstateArtefacts.type, reinstateArtefacts)
  yield takeLatest(actions.fetchCounterPartyDetail.type, fetchCounterPartyDetail)
  yield takeLatest(actions.fetchCsvData.type, fetchCsvData)
  yield takeLatest(actions.saveModalSignedContract.type, saveSignedContractModal)
  yield takeLatest(actions.fetchMasterContractData.type, fetchMasterContractData)
  yield takeLatest(actions.updateArtefactRenewed.type, updateArtefactRenewed)
  yield takeLatest(actions.updateRequestForWorkflow.type, updateRequestForWorkflow)
  yield takeLatest(actions.fetchUploadArtefactHistoryDoc.type, fetchUploadArtefactHistoryDoc)
  yield takeLatest(actions.fetchUploadArtefactStepById.type, fetchUploadArtefactStepById)
  yield takeLatest(actions.fetchAuditLogs.type, fetchAuditLogs)
  yield takeLatest(actions.inviteCollaborator.type, inviteCollaborator);
  yield takeLatest(actions.getCollaborators.type, getCollaborators)
  yield takeLatest(actions.getStepNames.type, getStepNames)
  yield takeLatest(actions.createTimeLog.type, createTimeLog);
  yield takeLatest(actions.editTimeLog.type, editTimeLog);
  yield takeLatest(actions.deleteTimeLog.type, deleteTimeLog);
  yield takeLatest(actions.getTimeLogForArtefact.type, getTimeLogForArtefact);
  yield takeLatest(actions.getWorkflowSteps.type, getWorkflowSteps);
  
}
